<template>
  <button class="simple-btn" :class="classes" @click="handler" :disabled="isDisabled">
    <slot name="icon"></slot>
    <slot></slot>
  </button>
</template>

<script>
export default {
  props: {
    color: String,
    font: String,
    additionalClass: String,
    reverse: String,
    isDisabled: Boolean,
    noRadius: {
      type: Boolean
    },
    isSmall: {
      type: Boolean
    }
  },
  methods: {
    handler() {
      this.$emit('clickedFromSimpleBtn')
    }
  },
  computed: {
    classes() {
      return {
        [`simple-btn--${this.color}`]: this.color,
        [`simple-btn--${this.font}`]: this.font,
        [`simple-btn--${this.reverse}`]: this.reverse,
        [`simple-btn--${this.additionalClass}`]: this.additionalClass,
        [`simple-btn--no-radius`]: this.noRadius,
        [`simple-btn--small`]: this.isSmall
      }
    }
  }
}
</script>

<style lang="sass" scoped>
.simple-btn
  font-family: $main-font
  height: rem(56)
  box-shadow: none
  outline: none
  border: none
  border-radius: rem(4)
  display: flex
  flex-direction: row
  justify-content: center
  align-items: center
  color: $white
  padding: 0 rem(20)
  transition: .5s
  cursor: pointer
  font-style: normal
  font-weight: bold
  font-size: rem(14)
  line-height: 140%
  background: $default
  text-align: left
  .icon
    margin-right: rem(20)
  &--red
    background: $red
    &:hover
      background: $red-hover
  &--green
    background: $green
    &:hover
      background: $green-hover
  &--default
    color: $black
  &--minor
    font-size: rem(12)
    line-height: 150%
  &--trash
    svg
      path
        transition: .5s
    &:hover
      background: $red-hover
      svg
        path
          stroke: #fff
  &--cog
    .icon
      transition: .5s
    &:hover
      .icon
        transform: rotate(-180deg)
  &--reverse
    flex-direction: row-reverse
    .icon
      margin-right: 0
      margin-left: rem(20)
  &:disabled
    cursor: no-drop
    background: $dark-grey
    &:hover
      background: $dark-grey
  &--no-radius
  &--small
    height: rem(40)
</style>
